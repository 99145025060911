import { Alert, Modal } from 'antd';
import { find, isEmpty } from 'lodash';
import { extendObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  Checkbox,
  DateMonthInput,
  Input,
} from '../../components/formComponents';
import LoadingPageOverlay from '../../components/LoadingPageOverlay';
import ThankYouOverlay from '../../components/ThankYouOverlay';
import withRouter from '../../components/withRouter';
import t from '../../service/translate.service';
import {
  buildValidators,
  isValidBIC,
  isValidIBAN,
  maxLength,
  required,
} from '../../service/validate';
import ContractVerificationPage from './ContractVerificationPage';
import { StackedNotifications } from '../../components/StackedNotifications';

class MyPaymentDetails extends Component {
  static propTypes = {
    contractStore: PropTypes.object.isRequired,
    location: PropTypes.object,
    menuStore: PropTypes.object.isRequired,
    configStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    extendObservable(this, {
      contractStore: props.contractStore,
      paymentMethod: '',
      originalPaymentMethod: '',
      isChangingToSepa: false,
      isChangingToSelf: false,
      emailVerificationEnabled: false,
      verificationStarted: false,
      submitSuccess: false,
      validateFailed: false,
      createOrUpdateFailed: false,
      showConfirmDataModal: false,
      alreadyValidatedOnce: false,
      canRequestPaymentMethodChange: true,
      notifications: [
        {
          content: '',
          contentDe: '',
          seen: false,
          type: '',
        },
      ],
      errorMessage: '',
      earliestStartFromDate: moment
        .utc()
        .startOf('day')
        .add(30, 'days')
        .startOf('month')
        .add(1, 'months')
        .format(), // newly added
      originalSepaDebitValues: {
        sepaAccountHolderFirstName: '',
        sepaAccountHolderLastName: '',
        sepaIban: '',
        sepaBic: '',
        sepaStartFrom: '',
        sepaGrantMandate: false,
      },
      originalSelfPaymentValues: {
        selfPaymentRecipient: '',
        selfPaymentIban: '',
        selfPaymentBic: '',
        selfPaymentStartFrom: '',
        selfPaymentActive: false,
      },
      selfPaymentValues: {
        selfPaymentRecipient: '',
        selfPaymentIban: '',
        selfPaymentBic: '',
        selfPaymentStartFrom: '',
        selfPaymentActive: false,
      },
      sepaDebitValues: {
        sepaAccountHolderFirstName: '',
        sepaAccountHolderLastName: '',
        sepaIban: '',
        sepaBic: '',
        sepaStartFrom: '',
        sepaGrantMandate: false,
      },
    });
  }

  sepaValidators() {
    return buildValidators(
      {
        sepaAccountHolderFirstName: [required, maxLength(60)],
        sepaAccountHolderLastName: [required, maxLength(60)],
        sepaIban: [required, isValidIBAN],
        sepaBic: [required, isValidBIC],
        sepaStartFrom: [required],
        sepaGrantMandate: [required],
      },
      this.props.contractStore.submitted
    );
  }

  selfValidators() {
    return buildValidators(
      {
        selfPaymentStartFrom: [required],
        selfPaymentActive: [required],
      },
      this.props.contractStore.submitted
    );
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.contractStore.submitted = false;
    const { location } = this.props;
    (async () => {
      const details =
        await this.props.contractStore.getPaymentMethodDetails(
          this.props.useParams.contractNumber
        );
      if (!isEmpty(details)) {
        this.paymentMethod = details.paymentMethod;
        this.canRequestPaymentMethodChange =
          details.canRequestPaymentMethodChange;
        this.earliestStartFromDate = new Date(
          details.earliestStartFromDate
        );

        if (!this.canRequestPaymentMethodChange) {
          this.notifications = details.notifications;
        }

        this.originalSepaDebitValues = {
          sepaAccountHolderFirstName:
            details.sepaAccountHolderFirstName,
          sepaAccountHolderLastName:
            details.sepaAccountHolderLastName,
          sepaIban: details.sepaIban,
          sepaBic: details.sepaBic,
          sepaStartFrom:
            details.sepaStartFrom !== null
              ? new Date(details.sepaStartFrom)
              : '',
          sepaGrantMandate: details.sepaGrantMandate,
        };

        this.originalSelfPaymentValues = {
          selfPaymentRecipient: details.selfPaymentRecipient,
          selfPaymentIban: details.selfPaymentIban,
          selfPaymentBic: details.selfPaymentBic,
          selfPaymentStartFrom:
            details.selfPaymentStartFrom !== null
              ? new Date(details.selfPaymentStartFrom)
              : '',
          selfPaymentActive: details.selfPaymentActive,
        };

        this.selfPaymentValues.selfPaymentRecipient =
          details.selfPaymentRecipient;
        this.selfPaymentValues.selfPaymentIban =
          details.selfPaymentIban;
        this.selfPaymentValues.selfPaymentBic =
          details.selfPaymentBic;
        this.selfPaymentValues.selfPaymentStartFrom =
          details.selfPaymentStartFrom !== null
            ? new Date(details.selfPaymentStartFrom)
            : '';
        this.selfPaymentValues.selfPaymentActive =
          details.selfPaymentActive;

        if (this.paymentMethod !== 'sepaDirect') {
          this.sepaDebitValues.sepaAccountHolderFirstName =
            details.sepaAccountHolderFirstName;
          this.sepaDebitValues.sepaAccountHolderLastName =
            details.sepaAccountHolderLastName;
          this.sepaDebitValues.sepaIban = details.sepaIban;
          this.sepaDebitValues.sepaBic = details.sepaBic;
          this.sepaDebitValues.sepaStartFrom =
            details.sepaStartFrom !== null
              ? new Date(details.sepaStartFrom)
              : '';
          this.sepaDebitValues.sepaGrantMandate =
            details.sepaGrantMandate;
        }
      }

      if (
        !isEmpty(location?.state?.rentPaymentMethodData) &&
        location?.state?.rentPaymentMethodData.changingToMethod
      ) {
        if (
          location?.state?.rentPaymentMethodData.changingToMethod ===
          'sepaDirect'
        ) {
          const response =
            await this.props.contractStore.changePaymentMethodToSepa(
              this.props.useParams.contractNumber,
              {
                sepaAccountHolderFirstName:
                  location?.state?.rentPaymentMethodData
                    .sepaAccountHolderFirstName,
                sepaAccountHolderLastName:
                  location?.state?.rentPaymentMethodData
                    .sepaAccountHolderLastName,
                sepaIban:
                  location?.state?.rentPaymentMethodData.sepaIban,
                sepaBic:
                  location?.state?.rentPaymentMethodData.sepaBic,
                sepaGrantMandate:
                  location?.state?.rentPaymentMethodData
                    .sepaGrantMandate,
                sepaStartFrom: moment
                  .utc(
                    location?.state?.rentPaymentMethodData
                      .sepaStartFrom
                  )
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
              }
            );

          if (response.data.success) {
            this.onCancel();
            this.submitSuccess = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
          } else {
            this.createOrUpdateFailed = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
            this.errorMessage = t[response.data.errors];
          }
        } else if (
          location?.state?.rentPaymentMethodData.changingToMethod ===
          'selfPayment'
        ) {
          const response =
            await this.props.contractStore.changePaymentMethodToSelfPayment(
              this.props.useParams.contractNumber,
              {
                selfPaymentStartFrom: moment
                  .utc(
                    location?.state?.rentPaymentMethodData
                      .selfPaymentStartFrom
                  )
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
                selfPaymentActive:
                  location?.state?.rentPaymentMethodData
                    .selfPaymentActive,
              }
            );

          if (response.data.success) {
            this.onCancel();
            this.submitSuccess = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
          } else {
            this.createOrUpdateFailed = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
            this.errorMessage = t[response.data.errors];
          }
        }
      }
    })();
  };

  translateFormKeys: (key: string) => string = (key) => {
    switch (key.trim()) {
      case 'sepaAccountHolderFirstName':
        return t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER_FIRST_NAME;
      case 'sepaAccountHolderLastName':
        return t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER_LAST_NAME;
      case 'sepaIban':
        return t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN;
      case 'sepaBic':
        return t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC;
      case 'sepaStartFrom':
        return t.RENT_PAYMENT_METHOD_CHANGE_START_FROM;
      case 'sepaGrantMandate':
        return t.RENT_PAYMENT_METHOD_CHANGE_GRANT_SEPA_MANDATE;
      case 'selfPaymentStartFrom':
        return t.RENT_PAYMENT_METHOD_CHANGE_START_FROM;
      case 'selfPaymentActive':
        return t.RENT_PAYMENT_METHOD_CHANGE_SELF_PAYMENT_ACTIVE;
      default:
        return key;
    }
  };

  handleTempPaymentMethodChange = (paymentMethod) => {
    this.originalPaymentMethod = this.paymentMethod;
    this.paymentMethod = paymentMethod;
    this.isChangingToSepa = paymentMethod === 'sepaDirect';
    this.isChangingToSelf = paymentMethod === 'selfPayment';
  };

  handleSepaDebitChange = (e) => {
    this.sepaDebitValues[e.target.name] = e.target.value;
  };

  handleSelfPaymentChange = (e) => {
    this.selfPaymentValues[e.target.name] = e.target.value;
  };

  handleValidationBeforeSubmit = () => {
    let isValid = true;
    this.props.contractStore.submitted = true;
    if (this.isChangingToSepa) {
      const validator = this.sepaValidators();
      isValid = !find(validator, (validate, field) => {
        return validate(this.sepaDebitValues[field]);
      });
    }

    if (this.isChangingToSelf) {
      const validator = this.selfValidators();
      isValid = !find(validator, (validate, field) => {
        return validate(this.selfPaymentValues[field]);
      });
    }

    return isValid;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = this.handleValidationBeforeSubmit();
      if (!isValid) {
        return;
      }

      if (this.alreadyValidatedOnce) {
        const emailVerificationEnabled =
          await this.props.menuStore.showEmailVerification();
        if (emailVerificationEnabled) {
          const { contractNumber } = this.props.useParams;
          const res =
            await this.props.contractStore.getContractVerificationInfo(
              contractNumber
            );
          if (!res) this.emailVerificationEnabled = true;
          const status =
            await this.props.contractStore.checkUserVerificationInfo();
          if (!status.email) {
            this.emailVerificationEnabled = true;
          }

          if (this.emailVerificationEnabled) {
            this.verificationStarted = true;
            return;
          }
        }

        if (this.isChangingToSepa) {
          const response =
            await this.props.contractStore.changePaymentMethodToSepa(
              this.props.useParams.contractNumber,
              {
                sepaAccountHolderFirstName:
                  this.sepaDebitValues.sepaAccountHolderFirstName,
                sepaAccountHolderLastName:
                  this.sepaDebitValues.sepaAccountHolderLastName,
                sepaIban: this.sepaDebitValues.sepaIban,
                sepaBic: this.sepaDebitValues.sepaBic,
                sepaStartFrom: moment
                  .utc(this.sepaDebitValues.sepaStartFrom)
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
                sepaGrantMandate:
                  this.sepaDebitValues.sepaGrantMandate,
              }
            );

          if (response.data.success) {
            this.onCancel();
            this.submitSuccess = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
          } else {
            this.createOrUpdateFailed = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
            this.errorMessage = t[response.data.errors];
          }
        }

        if (this.isChangingToSelf) {
          const response =
            await this.props.contractStore.changePaymentMethodToSelfPayment(
              this.props.useParams.contractNumber,
              {
                selfPaymentStartFrom: moment
                  .utc(this.selfPaymentValues.selfPaymentStartFrom)
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
                selfPaymentActive:
                  this.selfPaymentValues.selfPaymentActive,
              }
            );

          if (response.data.success) {
            this.onCancel();
            this.submitSuccess = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
          } else {
            this.createOrUpdateFailed = true;
            this.alreadyValidatedOnce = false;
            this.showConfirmDataModal = false;
            this.errorMessage = t[response.data.errors];
          }
        }
        return;
      }

      const response =
        await this.props.contractStore.validatePaymentData(
          this.props.useParams.contractNumber,
          this.isChangingToSepa
            ? {
                sepaAccountHolderFirstName:
                  this.sepaDebitValues.sepaAccountHolderFirstName,
                sepaAccountHolderLastName:
                  this.sepaDebitValues.sepaAccountHolderLastName,
                sepaIban: this.sepaDebitValues.sepaIban,
                sepaBic: this.sepaDebitValues.sepaBic,
                sepaStartFrom: moment
                  .utc(this.sepaDebitValues.sepaStartFrom)
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
                sepaGrantMandate:
                  this.sepaDebitValues.sepaGrantMandate,
              }
            : {
                selfPaymentStartFrom: moment
                  .utc(this.selfPaymentValues.selfPaymentStartFrom)
                  .add(1, 'day')
                  .startOf('day')
                  .format(),
                selfPaymentActive:
                  this.selfPaymentValues.selfPaymentActive,
              },
          this.isChangingToSepa ? 'sepaDirect' : 'selfPayment'
        );

      if (!response.success) {
        this.validateFailed = true;
        let invalidFields = response.errors.fields.split(',');
        let invalidFieldsTranslated = invalidFields.map((field) => {
          return this.translateFormKeys(field);
        });
        this.errorMessage =
          t.RENT_PAYMENT_METHOD_FORM_VALIDATION_FAILED +
          ' ' +
          invalidFieldsTranslated.join(', ');
        return;
      }
      this.errorMessage = '';
      this.validateFailed = false;
      this.createOrUpdateFailed = false;
      this.showConfirmDataModal = true;
      this.alreadyValidatedOnce = true;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  onCancel = () => {
    if (this.isChangingToSelf) {
      this.selfPaymentValues = { ...this.originalSelfPaymentValues };
    }

    if (this.isChangingToSepa) {
      this.sepaDebitValues = {};
    }
    this.paymentMethod = this.originalPaymentMethod;
    this.isChangingToSepa = false;
    this.isChangingToSelf = false;
    this.submitSuccess = false;
    this.validateFailed = false;
    this.createOrUpdateFailed = false;
    this.verificationStarted = false;
    this.props.contractStore.submitted = false;
    this.errorMessage = '';
  };

  renderForm() {
    return (
      <Form>
        {!this.canRequestPaymentMethodChange &&
          !isEmpty(this.notifications) && (
            <div className="homepage">
              <StackedNotifications
                notifications={this.notifications.map((alert) => ({
                  ...alert,
                  content:
                    localStorage.getItem('locale') === 'en'
                      ? alert.content
                      : alert.contentDe,
                }))}
                onContentClick={() => {}}
                onClose={() => {}}
              />
            </div>
          )}
        {(this.validateFailed || this.createOrUpdateFailed) && (
          <Alert message={this.errorMessage} type="error" />
        )}
        {this.emailVerificationEnabled &&
          this.verificationStarted && (
            <ContractVerificationPage
              configStore={this.props.configStore}
              isAddingContract={false}
              redirectUrl={`/my-payment-details/${this.props.useParams.contractNumber}`}
              changingToMethod={
                this.isChangingToSepa ? 'sepaDirect' : 'selfPayment'
              }
              rentPaymentMethodData={
                this.isChangingToSepa
                  ? {
                      sepaAccountHolderFirstName:
                        this.sepaDebitValues
                          .sepaAccountHolderFirstName,
                      sepaAccountHolderLastName:
                        this.sepaDebitValues
                          .sepaAccountHolderLastName,
                      sepaIban: this.sepaDebitValues.sepaIban,
                      sepaBic: this.sepaDebitValues.sepaBic,
                      sepaStartFrom: moment
                        .utc(this.sepaDebitValues.sepaStartFrom)
                        .add(1, 'day')
                        .startOf('day')
                        .format(),
                      sepaGrantMandate:
                        this.sepaDebitValues.sepaGrantMandate,
                    }
                  : {
                      selfPaymentStartFrom: moment
                        .utc(
                          this.selfPaymentValues.selfPaymentStartFrom
                        )
                        .add(1, 'day')
                        .startOf('day')
                        .format(),
                      selfPaymentActive:
                        this.selfPaymentValues.selfPaymentActive,
                    }
              }
              onCancel={() => {
                this.verificationStarted = false;
              }}
            />
          )}
        <div style={{ marginBottom: '23px' }}>
          <Input
            name="contractNumber"
            label={t['USER_TENANT_NUMBER']}
            type="text"
            value={
              this.props.contractStore.contractNumber ||
              this.props.useParams.contractNumber
            }
            helper={
              <span>
                {t.RENT_PAYMENT_METHOD_CHANGE_CONTRACT_INFO}
              </span>
            }
            disabled
          />
        </div>
        {!this.isChangingToSepa && !this.isChangingToSelf && (
          <>
            <Input
              name="selectedPaymentMethod"
              label={t['RENT_PAYMENT_METHOD_CHANGE_CURRENT_METHOD']}
              type="text"
              value={
                this.paymentMethod === 'selfPayment'
                  ? t.SELF_PAYMENT
                  : t.DIRECT_DEBIT
              }
              helper={
                <span>
                  {t.RENT_PAYMENT_METHOD_CHANGE_SELF_PAYMENT_INFO}:
                </span>
              }
              disabled
            />
            <table
              style={{ fontSize: 20, marginTop: 25, width: '50%' }}
            >
              <tbody>
                {this.paymentMethod === 'selfPayment' && (
                  <tr>
                    <strong>
                      <td className="rentPaymentChangeDetailsTd">
                        {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_RECIPIENT}:
                      </td>
                    </strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {this.selfPaymentValues.selfPaymentRecipient}
                    </td>
                  </tr>
                )}
                {this.paymentMethod === 'sepaDirect' && (
                  <tr>
                    <strong>
                      <td className="rentPaymentChangeDetailsTd">
                        {t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER}:
                      </td>
                    </strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {this.originalSepaDebitValues
                        .sepaAccountHolderFirstName +
                        ' ' +
                        this.originalSepaDebitValues
                          .sepaAccountHolderLastName}
                    </td>
                  </tr>
                )}
                <tr>
                  <strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN}:
                    </td>
                  </strong>
                  <td className="rentPaymentChangeDetailsTd">
                    {this.paymentMethod === 'selfPayment'
                      ? this.selfPaymentValues.selfPaymentIban
                      : this.originalSepaDebitValues.sepaIban}
                  </td>
                </tr>
                <tr>
                  <strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC}:
                    </td>
                  </strong>
                  <td className="rentPaymentChangeDetailsTd">
                    {this.paymentMethod === 'selfPayment'
                      ? this.selfPaymentValues.selfPaymentBic
                      : this.originalSepaDebitValues.sepaBic}
                  </td>
                </tr>
                {this.paymentMethod === 'selfPayment' && (
                  <tr>
                    <strong>
                      <td className="rentPaymentChangeDetailsTd">
                        {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_REFERENCE}:
                      </td>
                    </strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {this.props.contractStore.contractNumber
                        ? this.props.contractStore.contractNumber
                        : this.props.useParams.contractNumber}
                    </td>
                  </tr>
                )}
                <tr>
                  <strong>
                    <td className="rentPaymentChangeDetailsTd">
                      {t.RENT_PAYMENT_METHOD_CHANGE_START_FROM}:
                    </td>
                  </strong>
                  <td className="rentPaymentChangeDetailsTd">
                    {this.paymentMethod === 'selfPayment'
                      ? moment(
                          this.selfPaymentValues.selfPaymentStartFrom
                        ).format('MM.YYYY')
                      : moment(
                          this.originalSepaDebitValues.sepaStartFrom
                        ).format('MM.YYYY')}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </Form>
    );
  }

  renderSepaForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          name="sepaAccountHolderFirstName"
          label={
            t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER_FIRST_NAME
          }
          type="text"
          value={this.sepaDebitValues.sepaAccountHolderFirstName}
          onChange={this.handleSepaDebitChange}
          validate={
            this.sepaValidators()['sepaAccountHolderFirstName']
          }
        />
        <Input
          name="sepaAccountHolderLastName"
          label={
            t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER_LAST_NAME
          }
          type="text"
          value={this.sepaDebitValues.sepaAccountHolderLastName}
          onChange={this.handleSepaDebitChange}
          validate={
            this.sepaValidators()['sepaAccountHolderLastName']
          }
        />
        <Input
          name="sepaIban"
          label={t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN}
          type="text"
          value={this.sepaDebitValues.sepaIban}
          onChange={this.handleSepaDebitChange}
          validate={this.sepaValidators()['sepaIban']}
        />
        <Input
          name="sepaBic"
          label={t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC}
          type="text"
          value={this.sepaDebitValues.sepaBic}
          onChange={this.handleSepaDebitChange}
          validate={this.sepaValidators()['sepaBic']}
        />
        <DateMonthInput
          name="sepaStartFrom"
          label={t.RENT_PAYMENT_METHOD_CHANGE_START_FROM}
          value={this.sepaDebitValues.sepaStartFrom}
          onChange={(date) => {
            this.sepaDebitValues.sepaStartFrom = date;
          }}
          minDate={this.earliestStartFromDate}
          validate={this.sepaValidators()['sepaStartFrom']}
        />
        <Checkbox
          name="sepaGrantMandate"
          label={t.RENT_PAYMENT_METHOD_CHANGE_GRANT_SEPA_MANDATE}
          value={this.sepaDebitValues.sepaGrantMandate}
          onChange={(e) => {
            this.sepaDebitValues.sepaGrantMandate = e.target.checked;
          }}
          validate={this.sepaValidators()['sepaGrantMandate']}
        />
        {this.renderActionButtons()}
      </Form>
    );
  }

  renderSelfPaymentForm() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          name="selfPaymentRecipient"
          label={t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER}
          type="text"
          value={this.selfPaymentValues.selfPaymentRecipient}
          onChange={this.handleSelfPaymentChange}
          disabled
        />
        <Input
          name="selfPaymentIban"
          label={t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN}
          type="text"
          value={this.selfPaymentValues.selfPaymentIban}
          onChange={this.handleSelfPaymentChange}
          disabled
        />
        <Input
          name="selfPaymentBic"
          label={t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC}
          type="text"
          value={this.selfPaymentValues.selfPaymentBic}
          onChange={this.handleSelfPaymentChange}
          disabled
        />
        <DateMonthInput
          name="selfPaymentStartFrom"
          label={t.RENT_PAYMENT_METHOD_CHANGE_START_FROM}
          value={this.selfPaymentValues.selfPaymentStartFrom}
          onChange={(date) => {
            this.selfPaymentValues.selfPaymentStartFrom = date;
          }}
          minDate={this.earliestStartFromDate}
          validate={this.selfValidators()['selfPaymentStartFrom']}
        />
        <Checkbox
          name="selfPaymentActive"
          label={t.RENT_PAYMENT_METHOD_CHANGE_SELF_PAYMENT_ACTIVE}
          value={this.selfPaymentValues.selfPaymentActive}
          onChange={(e) => {
            this.selfPaymentValues.selfPaymentActive =
              e.target.checked;
          }}
          validate={this.selfValidators()['selfPaymentActive']}
        />
        {this.renderActionButtons()}
      </Form>
    );
  }

  renderActionButtons() {
    return (
      <div
        style={{
          marginTop: 40,
          display: 'flex',
          justifyContent: 'center',
          gap: 10,
        }}
      >
        {(this.isChangingToSepa || this.isChangingToSelf) && (
          <Button className="button primary" type="submit">
            {t.FORM_SUBMIT}
          </Button>
        )}

        <Button
          disabled={!this.canRequestPaymentMethodChange}
          className={`button ${
            this.isChangingToSepa || this.isChangingToSelf
              ? 'secondary'
              : 'primary'
          }`}
          type="reset"
          onClick={() => {
            this.isChangingToSepa || this.isChangingToSelf
              ? this.onCancel()
              : this.handleTempPaymentMethodChange(
                  this.paymentMethod === 'selfPayment'
                    ? 'sepaDirect'
                    : 'selfPayment'
                );
          }}
        >
          {this.isChangingToSepa || this.isChangingToSelf
            ? t.FORM_CANCEL
            : this.paymentMethod === 'selfPayment'
            ? t.RENT_PAYMENT_METHOD_CHANGE_DIRECT_DEBIT
            : t.RENT_PAYMENT_METHOD_CHANGE_SELF_PAYMENT_SELF}
        </Button>

        {this.paymentMethod === 'sepaDirect' &&
          !this.isChangingToSepa && (
            <Button
              disabled={!this.canRequestPaymentMethodChange}
              className="button primary"
              type="submit"
              onClick={() => {
                this.handleTempPaymentMethodChange('sepaDirect');
              }}
            >
              {t.CHANGE_SEPA_DETAILS}
            </Button>
          )}
      </div>
    );
  }

  render() {
    if (this.props.contractStore.loading) {
      return <LoadingPageOverlay />;
    }

    return (
      <div className="subpageContent">
        {this.submitSuccess ? (
          <ThankYouOverlay
            title={t.THANK_YOU}
            description={t.ADD_RENT_PAYMENT_METHOD}
            buttonText={t.BACK_HOME}
            buttonLink="/"
            handleClick={this.handleOk}
          />
        ) : (
          <>
            {this.showConfirmDataModal && (
              <Modal
                className="modalSubjectWrapper"
                open={this.showConfirmDataModal}
                title={t.RENT_PAYMENT_METHOD_CHANGE_CONFIRM_DATA}
                closable={false}
                centered={true}
                footer={[
                  <button
                    key="back"
                    className="button secondary"
                    onClick={() => {
                      this.showConfirmDataModal = false;
                      this.alreadyValidatedOnce = false;
                    }}
                    style={{ marginRight: 15 }}
                  >
                    {t.FORM_CANCEL}
                  </button>,
                  <button
                    key="submit"
                    className="button primary"
                    onClick={this.handleSubmit}
                  >
                    {t.FORM_CONFIRM}
                  </button>,
                ]}
              >
                {this.isChangingToSelf ? (
                  <div>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER}:{' '}
                      {this.selfPaymentValues.selfPaymentRecipient}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN}:{' '}
                      {this.selfPaymentValues.selfPaymentIban}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC}:{' '}
                      {this.selfPaymentValues.selfPaymentBic}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_START_FROM}:{' '}
                      {moment(
                        this.selfPaymentValues.selfPaymentStartFrom
                      ).format('MM.YYYY')}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_ACCOUNT_HOLDER}:{' '}
                      {this.sepaDebitValues
                        .sepaAccountHolderFirstName +
                        ' ' +
                        this.sepaDebitValues
                          .sepaAccountHolderLastName}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_IBAN}:{' '}
                      {this.sepaDebitValues.sepaIban}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_SEPA_BIC}:{' '}
                      {this.sepaDebitValues.sepaBic}
                    </p>
                    <p>
                      {t.RENT_PAYMENT_METHOD_CHANGE_START_FROM}:{' '}
                      {moment(
                        this.sepaDebitValues.sepaStartFrom
                      ).format('MM.YYYY')}
                    </p>
                  </div>
                )}
              </Modal>
            )}
            <div className="subtitle">
              {t.RENT_PAYMENT_METHOD_CHANGE}
            </div>
            {this.renderForm()}
            {this.isChangingToSepa
              ? this.renderSepaForm()
              : this.isChangingToSelf
              ? this.renderSelfPaymentForm()
              : null}
            {!this.isChangingToSepa &&
              !this.isChangingToSelf &&
              this.renderActionButtons()}
          </>
        )}
      </div>
    );
  }
}

export default inject(
  'contractStore',
  'menuStore',
  'configStore'
)(withRouter(observer(MyPaymentDetails)));
