import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { InboxImage } from "../../components/icons";
import PrimaryTitle from "../../components/PrimaryTitle";
import NoContent from "../../components/NoContent";
import t from "../../service/translate.service";
import PropTypes from "prop-types";
import iconsArrowRight from "../../assets/svg/iconsArrowRight.svg";
import IconsCloseSmall from "../../assets/svg/iconsCloseSmall.svg";
import moment from "moment";
import RepairsAndIquiriesStore from "../../store/repairsAndInquiries.store";
import { getAlertInfo } from "../../service/alert.service";
import withRouter from "../../components/withRouter";

class InboxPage extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.store = new RepairsAndIquiriesStore();
    this.store.getReports();
  }

  handleClick = (type) => {
    this.props.alertStore.selectedCategory = type;
  };

  openReport = (id) => {
    if (this.props.alertStore.selectedCategory === "damage_report") {
      const selectedReport = this.store.reports.filter((r) => r.id === id)[0];
      this.store.selectedReport = selectedReport;
      this.store.showResolved = selectedReport.statusId === 3;
      this.props.menuStore.setCurrentPage("/repairs-and-inquiries");
      this.props.navigate("/repairs-and-inquiries");
    }
    if (this.props.alertStore.selectedCategory === "oc_inspection") {
      this.props.menuStore.setCurrentPage("/receipt-inspection");
      this.props.navigate("/receipt-inspection");
    }
    if(this.props.alertStore.selectedCategory === "contract") {
      this.props.menuStore.setCurrentPage("/contracts");
      this.props.navigate("/contracts");
    }
  };

  extractContractNumberFromAlert = (content) => {
    if (!content || typeof content !== 'string') return; 
    const regex = /<b>(.*?)<\/b>/
    const matches = content.match(regex)
    if (!matches) return;
    return matches[1].trim()
  }

  renderInboxAlert = (alert) => {
    if (alert.type !== this.props.alertStore.selectedCategory) return;
    if(!this?.props?.configStore?.verificationEnabled && alert.status === 'UNVERIFIED') {
      alert.status = 'VERIFIED'
    }
    const {statusClass, content, sName, isUrl, lang} = getAlertInfo(alert)
    const contractNumber = this.extractContractNumberFromAlert(content) 

    return (
      <div className="item" key={alert.id}>
        <div 
          className={`container ${(alert.type === 'contract' || alert.type === 'damage_report') ? 'openAlert' : ''}`} 
          onClick={() => {
            if (alert.type === 'certificate') {
              if (this.props.configStore.rentContractDocumentsEnabled) {
                this.props.navigate(`/rent-contract-documents/${contractNumber}`)
              }
            }
          }}
          style={ alert.type === 'certificate' ? { cursor: 'pointer' } : null }
          >
          <div
            className="details"
            onClick={() => {
              this.props.alertStore.markAlertRead(alert.id);
              this.openReport(alert.reportId);
            }}
          >
            <div className="group">
              <div className="status">
                <span className={`status ${statusClass}`}>
                  {t[statusClass]}
                </span>{" "}
                {sName} {!alert.seen && <span className="new">{t.NEW}</span>}
              </div>
              {!isUrl ? (
                <div
                  className="contract-number"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                ></div>
              ) : (
                <div>
                  <p>{content}</p>
                </div>
              )}

              <div className="date">
                {moment(alert.created_at)
                  .lang(
                    `${localStorage.getItem("locale") === "de" ? "de" : "en"}`
                  )
                  .format("dddd, MMMM Do YYYY, HH:mm")}
              </div>
            </div>
            {this.props.alertStore.selectedCategory === "damage_report" && (
              <div className="icon next">
                <img src={iconsArrowRight} />
              </div>
            )}
          </div>
          <div
            className="close"
            style={{ padding: '.5rem' }}
            onClick={(e) => {
              e.stopPropagation()
              this.props.alertStore.deleteAlert(alert.id);
            }}
          >
            <img src={IconsCloseSmall} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const categoryStyle = {
      paddingRight: 30,
    };
    const { alerts, categories, selectedCategory } = this.props.alertStore;
    return (
      <div>
        <PrimaryTitle title={t.INBOX_TITLE} />

        {!alerts.length && (
          <NoContent message={t.INBOX_EMPTY} image={InboxImage} />
        )}

        {!!alerts.length && (
          <div className="category-tabs">
            {categories.map((c) => {
              if (!c.count) return;
              return (
                <span
                  className={selectedCategory === c.type ? "active" : ""}
                  key={c.type}
                  style={categoryStyle}
                  onClick={() => this.props.alertStore.handleClick(c.type)}
                >
                  <h3>{c.name}</h3>
                  {!!c.newCount && <div className="new">{c.newCount}</div>}
                </span>
              );
            })}
          </div>
        )}

        <div className="alert-list">
          {alerts.map((alert) => this.renderInboxAlert(alert))}
        </div>
      </div>
    );
  }
}
export function observableWithRouter(component) {
  return withRouter(observable(component));
}

InboxPage.propTypes = {
  menuStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
  configStore: PropTypes.object.isRequired,
};
export default inject(
  "alertStore",
  "menuStore",
  "configStore"
)(withRouter(observer(InboxPage)));
