import React from 'react'
import t from '../service/translate.service'
import MaskedInput from 'react-text-mask'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import UploadPreview from './UploadPreview'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarIcon from './icons/calendar'

export const Input = ({
  label,
  name = '',
  value,
  onChange,
  validate,
  type = 'text',
  placeholder = t.FORM_PLACEHOLDER(label),
  helper,
  disabled,
  isLabel = true,
  styles,
  autoComplete = true
}) => {
  return (
    <Form.Group style={styles} className='form-group'>
      { isLabel && <Form.Label>{label}</Form.Label> }
      {disabled ? <Form.Control
        disabled
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder} />
        : (
          <Form.Control
            type={type}
            value={value}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            autoComplete={autoComplete.toString()}
          />
        )}

      {handleErrorMessage({ validate, value, label })}
      {helper && <Form.Text className='text-muted'>{helper}</Form.Text>}
    </Form.Group>
  )
}
Input.propTypes = {
  label: PropTypes.string,
  isLabel: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  helper: PropTypes.object,
  disabled: PropTypes.bool,
  styles: PropTypes.object
}
export const Textarea = ({
  label,
  value,
  placeholder = t.FORM_PLACEHOLDER(label),
  onChange,
  validate,
  type = 'text',
  name = '',
  maxLength = 100000
}) => {
  return (
    <Form.Group className='form-group'>
      <div className='input-div'>
        <Form.Label>{label}</Form.Label>
        <textarea
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          rows={6}
          className='TextInput'
          type={type}
          value={value}
          onChange={onChange}
        >
          {value}
        </textarea>
        {handleErrorMessage({ validate, value, label })}
      </div>
    </Form.Group>
  )
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
}
export const Checkbox = ({ label, value, onChange, validate, error, labelBeforeText, labelLink, labelLinkText, labelAfterText, name}) => {
  return (
    <Form.Group className='form-group'>
      {label ? (<label className='custom-checkmark'>
        <input type='checkbox' checked={value} onChange={onChange} name={name} />
        <div className='checkmark' />
        <div className='content'>{label}</div>
      </label>) : (<label className='custom-checkmark'>
        <input type='checkbox' checked={value} onChange={onChange} name={name} />
        <div className='checkmark' />
        <div className='content'>{labelBeforeText} <a href={labelLink} rel="nofollow noreferrer noopener" target='_blank'>{labelLinkText}</a>{labelAfterText}</div>
      </label>)}
      {handleErrorMessage({ validate, value, label })}
    </Form.Group>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  labelLink: PropTypes.string,
  labelLinkText: PropTypes.string,
  labelBeforeText: PropTypes.string,
  labelAfterText: PropTypes.string,
  value: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  name: PropTypes.string
}

export const Masked = ({
  label,
  value,
  onChange,
  validate,
  mask,
  placeholder = t.FORM_PLACEHOLDER(label)
}) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <MaskedInput
        className='TextInput'
        keepCharPositions
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        mask={mask}
      />
      <div className='error'>{validate && validate(value, label)}</div>
    </Form.Group>
  )
}

Masked.propTypes = {
  label: PropTypes.string.isRequired,
  mask: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func,
  placeholder: PropTypes.string
}
const colourStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderColor: '#dfdfdf!important',
    outline: 'none',
    borderRadius: '0ox',
    padding: '10px 10px',
    boxShadow: 'none'
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: '#a1a1a1',
    fontSize: '16px'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: '#212529',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#eee'
    },
    ':active': {
      backgroundColor: '#eee'
    }
  }),
  singleValue: (baseStyles, state) => ({
    ...baseStyles,
    color: '#212529',
    fontSize: '16px'
  }),
}
export const CustomSelect = ({
  label,
  value,
  onChange,
  onInputChange,
  validate,
  options,
  placeholder = t.FORM_SELECT_PLACEHOLDER(label),
  isSearchable = false,
  inputValue,
  styles,
  defaultValue,
  isDisabled
}) => {
  return (
    <Form.Group className='form-group'>
      <Form.Label>{label}</Form.Label>
      <div style={styles}>
        <Select
          styles={colourStyles}
          defaultValue={defaultValue}
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          onInputChange={onInputChange}
          isSearchable={isSearchable}
          inputValue={inputValue}
          closeMenuOnSelect
          isDisabled={isDisabled}
        />
      </div>
      {handleErrorMessage({ validate, value, label })}
    </Form.Group>
  )
}
export const ImageUploader = props => {
  const { validate } = props
  return (
    <Form.Group>
      <UploadPreview {...props} />
      {handleErrorMessage({ validate })}
    </Form.Group>
  )
}
ImageUploader.propTypes = {
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}
CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number
  ]),
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ]),
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  defaultValue: PropTypes.object,
  isDisabled: PropTypes.bool
}

export const DateInput = ({
  label,
  value,
  onChange,
  validate,
  minDate,
  name = '',
  isLabel = true
}) => {
  return (
    <Form.Group className='form-group'>
      { isLabel && <Form.Label>{label}</Form.Label> }
      <div className='date-input-wrap'>
        <DatePicker
          dateFormat='dd-MM-yyyy'
          selected={value}
          minDate={minDate}
          onChange={onChange}
          showYearDropdown
          adjustDateOnChange
          name={name}
          yearDropdownItemNumber={4}
        />
        <div className='icon-wrap'>
          { CalendarIcon }
        </div>
      </div>
      {handleErrorMessage({ validate, value, label })}
    </Form.Group>
  )
}
DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.string,
  isLabel: PropTypes.bool
}

export const DateMonthInput = ({
  label,
  value,
  onChange,
  validate,
  minDate,
  name = '',
  isLabel = true
}) => {
  return (
    <Form.Group className='form-group'>
      { isLabel && <Form.Label>{label}</Form.Label> }
      <div className='date-input-wrap'>
        <DatePicker
          selected={value}
          minDate={minDate}
          onChange={onChange}
          dateFormat="MMMM yyyy"
          showMonthYearPicker
          adjustDateOnChange
          name={name}
          yearDropdownItemNumber={4}
        />
        <div className='icon-wrap'>
          { CalendarIcon }
        </div>
      </div>
      {handleErrorMessage({ validate, value, label })}
    </Form.Group>
  )
}
DateMonthInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  name: PropTypes.string,
  isLabel: PropTypes.bool
}

function handleErrorMessage ({ validate, value, label }) {
  return (
    validate && (
      <div className='error-message'>
        {typeof validate === 'string' ? validate : validate(value, label)}
      </div>
    )
  )
}
function handleCheckboxErrorMessage ({ validate, value, label }) {
  return (
    validate && (
      <div className='error-message'>
        {typeof validate === 'string' ? validate : validate(value, label)}
      </div>
    )
  )
}
