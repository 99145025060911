import React, { Component } from "react";
import propTypes from "prop-types";
import {toJS} from "mobx"
import { observer, inject } from "mobx-react";
import withRouter from "../../components/withRouter";
import { Button } from "react-bootstrap";
import { Button as AndtButton } from "antd";
import { Checkbox } from "../../components/formComponents";
import t from "../../service/translate.service";
import moment from "moment";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import { Alert, Modal } from "antd";
import {
  extractError,
  extractErrorFromSap,
} from "../../service/response.service";
import { Link } from "react-router-dom";
import rentContractDocumentStore from "../../store/rentContractDocuments.store";
import { saveAs } from "file-saver";
import CertificateAlerts from "../../components/CertificateAlerts";
import { orderBy } from "lodash";
import ContractVerificationPage from "./ContractVerificationPage";
import { autorun } from "mobx";
import DOMPurify, { sanitize } from "dompurify";

class ContractOverview extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
    consumptionStore: propTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      contract: "",
      errorMsg: "",
      loading: true,
      isShowModal: false,
      modalContent: null,
      contractVerified: false,
      verificationStarted: false,
      verificationEnabled: false,
    };
  }

  async componentDidMount() {
    this.props.contractStore.clearError()
    await this?.props?.contractStore?.initContracts()
    const contractNumber = this?.props?.contractStore?.selectedContract?.contractNumber

    try {
      const id = this.props.useParams.contractNumber;
      const selectedContract = this?.props?.contractStore?.activeContracts?.find(
        (c) => c.contractNumber === id
      );
      if (!selectedContract?.rentAccountBalance) {
        await this?.props?.contractStore?.fetchAccountBalance(selectedContract);
      }
      this.setState({ contract: selectedContract });
      this.contract = selectedContract;
      if(this?.props?.configStore?.verificationEnabled) {
        const res = await this?.props?.contractStore?.getContractVerificationInfo(
          contractNumber
        );
        if(res) this.setState({contractVerified: true})
        this.props.contractStore.getContractDetails(
          contractNumber
        );
      } else this.props.contractStore.getContractDetails(contractNumber)
      const overdueToApp =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          contractNumber,
          "Overdue"
        );
      this.setState({ overdueToApp });
      await this.props.contractStore.getCertificates(id);
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  optIn = async () => {
    try {
      this.setState({
        loading: true,
        overdueToApp: !this.state.overdueToApp,
      });

      await this.props.consumptionStore.consumptionRegister(
        this.state.contract,
        !this.state.overdueToApp,
        "Overdue"
      );

      const response =
        await this.props.consumptionStore.getConsumptionRegistrationInfo(
          this.state.contract.contractNumber,
          "Overdue"
        );

      this.setState({ overdueToApp: response });
    } catch (err) {
      this.setState({
        loading: false,
        errorMsg:
          this.props.contractStore.error ||
          extractErrorFromSap(err) ||
          t[extractError(err)] ||
          t["ERROR"],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  showModal = () => {
    this.modal.visible = true;
  };

  openBalanceHistory = (contractNumber) => {
    this.props.navigate(`/rent-account/${contractNumber}`);
  };

  openPrepayments = (contractNumber) => {
    this.props.navigate(`/contracts/prepayments/${contractNumber}`)
  }

  createCertificateRequest = async (contractNumber, type) => {
    const certificate = await this.props.contractStore.createCertificate(contractNumber, type)

    const translation = this.props.contractStore.error.includes('ZTEN')
      ? this.props.contractStore.error
      : this.props.contractStore.error
        ? "CERTIFICATE_RENT_MODAL_ERROR"
        : `CERTIFICATE_RENT_${certificate.type.toUpperCase()}_MODAL`

    const text = this.props.contractStore.error.includes('ZTEN')
      ? (
        <>
          {t[`${translation}_TEXT_1`]}
          <a
            style={{
              color: 'var(--brand-dark-grey)',
              borderBottom: '1px solid var(--brand-green)',
              textDecoration: 'none'
            }}
            href={DOMPurify.sanitize(
              `/repairs-and-inquiries/new-request?contractNumber=${contractNumber}`
            )}
          >
            {t[`${translation}_TEXT_2`]}{" "}
          </a>
          {t[`${translation}_TEXT_3`]}
        </>
      )
      : t[`${translation}_TEXT`]

    this.setState({
      isShowModal: true,
      modalContent: (
        <>
          <h2>{t[`${translation}_TITLE`]}</h2>
          <p style={{ fontWeight: '400', lineHeight: '1.5' }}>{text}</p>
        </>
      )
    })
  }

  downloadDocument = async (contractNumber, documentId, documentName) => {
    this.setState({loading: true})
    try {
      const file = await rentContractDocumentStore.getDocument(
        contractNumber,
        documentId,
        documentName
      );
      saveAs(file, documentName, {});
      this.setState({loading: false})
    } catch (err) {
      this.setState({loading: false, errorMsg: t['CERTIFICATE_RENT_DOWNLOAD_ERROR']})
      window.scrollTo(0, 0);
    }
  };

  startVerificationProcess = () => {
    this.setState({verificationStarted: true})
  }

  canUserSeeActionButton = (role, button) => {
    switch (button) {
      case 'rentContractDocumentsEnabled':
      case 'rentAccountBalanceEnabled':
      case 'ocCalculationsEnabled':
      case 'operationalCostPrepaymentEnabled':
      case 'rentDueOptInEnabled':
        return ['TR0600', 'TR0601', 'Betreuer', 'Bewohner'].includes(role)
      case 'changeRentPaymentMethodEnabled':
        return ['TR0600', 'TR0601'].includes(role)
      default:
        return false
    }
  }

  render() {
    const { loading, overdueToApp, contract, errorMsg } = this.state;
    const contractNumber = this?.props?.contractStore?.selectedContract?.contractNumber || ''

    if (loading || this.props.contractStore.loading) {
      return <LoadingPageOverlay />;
    }

    const {
      contractStartDate,
      contractEndDate,
      coldCosts,
      operationalCosts,
      heatingCosts,
      tenants,
      totalCost,
      contractDetailsError,
      certificates,
      operationalCostPrepaymentEnabled,
      error,
      verificationStarted,
      userVerified,
      enabledCertificates,
      paymentMethod,
      role,
      partnerId
    } = this.props.contractStore;

    if (contractDetailsError.length) {
      return (
        <Alert
          message={contractDetailsError}
          type="error"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        />
      );
    }

    return (
      <>
        {this.state.isShowModal && (
          <Modal
            title={this.state.modalContent}
            open={true}
            closable={false}
            onOk={() => this.setState({ isShowModal: false })}
            style={{ marginBottom: "12px", marginTop: "12px" }}
            cancelButtonProps={{ style: { display: 'none' } }}
          />
        )}

        <CertificateAlerts
          certificates={enabledCertificates}
          contractVerified={this.state.contractVerified}
        />

        {error && !error.includes('ZTEN') && (
          <Alert
            message={error}
            type="error"
            style={{ marginBottom: "12px", marginTop: "12px" }}
          />
        )}

        {this.state.verificationStarted &&  (
        <ContractVerificationPage configStore={this.props.configStore} isAddingContract={false} redirectUrl={`/contract-overview/${this.contract.contractNumber}`}/>
      )}

        <div className="contract-list">
          {errorMsg && (
            <Alert
              message={errorMsg}
              type="error"
              style={{ marginBottom: "12px", marginTop: "12px" }}
            />
          )}
          <div>

          {!this.state.contractVerified && this.props?.configStore?.verificationEnabled && (
            <AndtButton
              onClick={() => {
                this.startVerificationProcess()
                this.props.contractStore.clearError()
              }}
              className="contractVerificationStatus"
            >
              {t.CONTRACT_VERIFICATION_BTN}
            </AndtButton>
          )}

            <table style={{ fontSize: 20 }}>
              <thead>
                <tr className="contractOverviewth">
                  <th className="contractOverviewtd" style={{ width: "300px" }}>
                    {t.CONTRACT_OVERVIEW_DETAILS}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="contractOverviewtd">
                    {t.LOGIN_PAGE_TENANT_NUMBER}:
                  </td>
                  <td className="contractOverviewtd">
                    {contractNumber}
                  </td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">
                    {t.RENT_ACCOUNT_START_DATE}:
                  </td>
                  <td className="contractOverviewtd">
                    {moment(contractStartDate).isValid()
                      ? moment(contractStartDate).format("DD.MM.YYYY")
                      : ""}
                  </td>
                </tr>
                {contractEndDate.split('-')[0] !== '9999' && moment(contractEndDate).isValid() && (
                  <tr>
                    <td className="contractOverviewtd">
                      {t.RENT_ACCOUNT_END_DATE}:
                    </td>
                    <td className="contractOverviewtd">
                        {moment(contractEndDate).format("DD.MM.YYYY")}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="contractOverviewtd">{t.RENT_PAYMENT_METHOD}:</td>
                  <td className="contractOverviewtd">
                    {paymentMethod === 'selfPayment' ? t.SELF_PAYMENT : t.DIRECT_DEBIT}
                  </td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.USER_ADDRESS}:</td>
                  <td className="contractOverviewtd">{this?.contract?.address}</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.TENANTS}:</td>
                  <td className="contractOverviewtd">
                    {tenants &&
                      tenants.map((tenant, index) => (
                        <p
                          key={index}
                          className="contractOverviewtd"
                          style={{ paddingBottom: 0, marginBottom: "8px" }}
                        >
                          {tenant.firstName} {tenant.lastName}
                        </p>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ fontSize: 20, marginTop: 30 }}>
              <thead>
                <tr className="contractOverviewth">
                  <th className="contractOverviewtd" style={{ width: "300px" }}>
                    {t.MY_RENT}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="contractOverviewtd">{t.COLD_RENT}:</td>
                  <td className="contractOverviewtd">{coldCosts}</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.OPERATIONAL_COSTS}:</td>
                  <td className="contractOverviewtd">{operationalCosts}</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.HEATING_COSTS}:</td>
                  <td className="contractOverviewtd">{heatingCosts}</td>
                </tr>
                <tr>
                  <td className="contractOverviewtd">{t.TOTAL_COST}:</td>
                  <td className="contractOverviewtd">{totalCost}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ marginTop: 40 }} className="loyalty-upload">
            {this.canUserSeeActionButton(role, 'rentAccountBalanceEnabled') && (
              <div className="text-center">
                <Button
                  className="button primary"
                  onClick={() => this.openBalanceHistory(contractNumber)}
                >
                  {`${t.CONTRACT_RENT_ACCOUNT_BALANCE}`}
                </Button>
            </div>
            )}
            {this?.props?.configStore?.rentContractDocumentsEnabled && this.canUserSeeActionButton(role, 'rentContractDocumentsEnabled') && (
              <div className="text-center">
                <Button
                  onClick={() =>
                    this.props.navigate(
                      `/rent-contract-documents/${contractNumber}`
                    )
                  }
                  className="button primary"
                >
                  {t.RENT_CONTRACT_DOCUMENTS}
                </Button>
              </div>
            )}
            {this.props.contractStore.changeRentPaymentMethodEnabled && this.canUserSeeActionButton(role, 'changeRentPaymentMethodEnabled') && (
              <div className="text-center">
                <Button
                  onClick={() =>
                    this.props.navigate(
                      `/my-payment-details/${contractNumber}`
                    )}
                  className="button primary"
                >
                  {t.MY_PAYMENT_DETAILS}
                </Button>
              </div>
            )}
            {this.props.contractStore.ocCalculationsEnabled && this.canUserSeeActionButton(role, 'ocCalculationsEnabled') && (
              <div className="text-center">
                <Button
                  onClick={() =>
                    this.props.navigate(
                      `/oc-calculations/${contractNumber}`
                    )
                  }
                  className="button primary"
                >
                  {t.VIEW_OC_CALCULATION}
                </Button>
              </div>
            )}
            {this?.props?.configStore?.operationalCostPrepaymentEnabled && this.canUserSeeActionButton(role, 'operationalCostPrepaymentEnabled') &&
                  <Button
                  className="button primary"
                  onClick={() => this.openPrepayments(contractNumber)}
                >
                  {t.PREPAYMENT_OVERVIEW_BUTTON}
                </Button>
            }
            {enabledCertificates.map(certificate => {
              const { isDisabled, isDownload, cooldown, type, documentId, documentName, requestedAt } = certificate
              const text = t[`CERTIFICATE_RENT_${type.toUpperCase()}_BTN`]
              return (
                <Button
                  key={type}
                  style={{ position: 'relative' }}
                  className="button primary"
                  onClick={() => isDownload
                      ? this.downloadDocument(contractNumber, documentId, documentName)
                      : this.createCertificateRequest(contractNumber, type)
                    }
                  disabled={isDisabled}
                >
                  <>
                    {isDownload ? t[`CERTIFICATE_RENT_${type}_DOWNLOAD_BTN`] : text}
                    {requestedAt && cooldown && <span
                      style={{
                        position: 'absolute',
                        top: '105%',
                        left: 0,
                        width: '100%',
                        fontSize: '.75rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#292929',
                      }}
                    >
                      {`${[
                        t['CERTIFICATE_RENT_RPC_COOLDOWN_1'],
                        cooldown,
                        t['CERTIFICATE_RENT_RPC_COOLDOWN_2']
                      ].join(' ')}`}
                    </span>}
                  </>
                </Button>
              )
            })}
            {this.canUserSeeActionButton(role, 'rentDueOptInEnabled') && (
              <Checkbox
                label={t.CONTARCT_RENT_DUE_OPT_IN}
                value={overdueToApp}
                onChange={this.optIn}
              />
          )}
          </div>
        </div>
      </>
    );
  }
}

export default inject(
  "contractStore",
  "consumptionStore",
  "configStore"
)(withRouter(observer(ContractOverview)));
