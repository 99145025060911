import React, {Component} from 'react';
import receiptsStore from "../../store/receipts.store";
import pdfSvg from "../../assets/svg/pdf.svg";
import icTmpPreview from "../../assets/ic-tmp-preview.png";
import icTmpDownload from "../../assets/ic-tmp-download.png";
import t from "../../service/translate.service";
import NoContent from "../../components/NoContent";
import {ContractsImage} from "../../components/icons";
import {saveAs} from 'file-saver';
import {Link} from "react-router-dom";
import ReceiptInspectionForm from "./ReceiptInspectionForm";
import {Form} from 'react-bootstrap';
import withRouter from "../../components/withRouter";
import {observer} from "mobx-react";
import {api} from "../../service/api.service";
import LoadingPageOverlay from '../../components/LoadingPageOverlay';
import { extendObservable } from 'mobx';

class ReceiptInspection extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      loading: false
    })
  }

  componentDidMount = () => {
    (async() => {
      window.scrollTo(0, 0)
      await receiptsStore.fetchReceipts()
    })()
  }

  async getDocument(docName, contractNumber, docId, caseNumber, year) {
    const res = await api.get(`/receipts/${docName}/${contractNumber}/documents`, {
      params: {
        docId,
        caseNumber,
        year
      },
      responseType: 'blob',
    })
    return res.data
  }

  downloadDocument = async (document, contractNumber, caseNumber, year) => {
    this.loading = true
    const file = await this.getDocument(document?.name, contractNumber, document?.id, caseNumber, year);
    saveAs(file, document.name, {});
    this.loading = false
  }

  selectContract = (number) => {
    receiptsStore.selectContractNumber(number);
  }

  render() {
    if (receiptsStore.loadingReceipts || this.loading) return  <LoadingPageOverlay />
    if (!!receiptsStore.receiptContracts.length) {
      return (
        <div>
          <Form.Select onChange={(e) => this.selectContract(e.target.value)}
                       value={receiptsStore.selectedContractNumber} className='mb-4'>
            {
              receiptsStore.receiptContracts.map((c) => {
                return (
                  <option value={c} key={c}>{c}</option>
                )
              })
            }
          </Form.Select>
          <div className='documents-list' style={{marginBottom: '30px'}}>
            {
              receiptsStore.selectedContractReceipts.length > 0 ? (
                receiptsStore.selectedContractReceipts.map((r, i) => {
                  return (
                    <div key={i} className='item'>
                      <div className='container'>
                        <div className='details'>
                          <div className='svg'>
                            <img src={pdfSvg} alt='PDF'/>
                          </div>
                          <div className='group'>
                            <div className='label'>{r.name}</div>
                          </div>
                        </div>
                        <div className='actions'>
                          <Link className='open-preview'
                                to={`/receipt-inspection/${receiptsStore.selectedContractData.contractNumber}/${receiptsStore.selectedContractData.caseNumber}/${receiptsStore.selectedContractData.BK_Jahr}/${r.name}/${r.id}/preview`}>
                            <img src={icTmpPreview} alt='Preview'/>
                          </Link>
                          <a className='start-download' onClick={() => this.downloadDocument(r, receiptsStore.selectedContractData.contractNumber, receiptsStore.selectedContractData.caseNumber, receiptsStore.selectedContractData.BK_Jahr)}>
                            <img src={icTmpDownload} alt='Download'/>
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <NoContent message={t.NO_RECEIPTS} image={ContractsImage}/>
              )
            }
          </div>
          {receiptsStore.selectedContractReceipts.length > 0 && (
            <ReceiptInspectionForm contractData={receiptsStore.selectedContractData}/>
          )}
        </div>
      )
    } else {
      return (
        <NoContent message={t.NO_RECEIPTS} image={ContractsImage}/>
      )
    }
  }
}

export default withRouter(observer(ReceiptInspection))