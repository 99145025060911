import React, { useEffect, useState } from 'react'
import DocumentStore from '../../store/documentForm.store'
import { useNavigate } from "react-router-dom";
import { documents } from '../../config'
import { useParams } from 'react-router-dom';
import { api } from '../../service/api.service';
import PropTypes from 'prop-types'

const ViewDocument = (props) => {
const navigate = useNavigate();
const [file, setFile] = useState()
let { name } = useParams()


const getFile = async (filename)  => {
  const url = '/file/' + filename
  const res = await api
    .get(url, {
      responseType: 'blob'
    })
  return res.data
}

  useEffect(() => {
   if(documents) {
   const foundFile = documents.find((d) => d.fileName === name)
   if(foundFile && foundFile?.fileName) {
    getFile(foundFile.fileName).then((res) => setFile(res))
   } else setFile(props.file)
   }
  }, [documents])

  return (
    <div className='document-preview'>
      <button className='close-preview' onClick={() => {
            DocumentStore.file = null
            DocumentStore.preview = false
            navigate('/documents')
      } }>x</button>
      {file &&
           <div className='text-center'>
           <iframe src={URL.createObjectURL(file)} sandbox toolbar={false}/>
         </div>
      }

    </div>
  )
}
ViewDocument.propTypes = {
  file: PropTypes.object,
}
export default ViewDocument
