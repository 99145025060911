import HomepageOverlay from "./HomepageOverlay";
import { checkIsUserCondominium, checkIfUserHasMixedContracts } from "../service/user.service";
import PropTypes from 'prop-types'
import find from "lodash/find";
import { quickLinksHome, quickLinksServices } from '../config'
import { useEffect, useState } from "react";
import AdventStore from "../store/Advent.store";
import LoadingPageOverlay from "./LoadingPageOverlay";

const TenantServicesList = ({menuStore, onClick, User, configStore}) => {
  const [quickLinks, setQuickLinks] = useState([])
  const [loading, setLoading] = useState([])
  const hasActiveContracts = Boolean(find(User.contracts, (c) => c.valid && c.active));
  let isCondominium = false;
  let mixedContracts = false
  if (User.contracts) {
    isCondominium = checkIsUserCondominium(User.contracts);
    mixedContracts = checkIfUserHasMixedContracts(User.contracts)
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      const quickLinks = window.location.pathname === '/' ? quickLinksHome : quickLinksServices
      try {
        const res = await AdventStore.getAll()
        const extraContent = []
        for (let i = 0; i < res?.rows?.length; i++) {
          const item = res?.rows[i]
          if (item.active) {
            extraContent.push({
              image: <img src={`${item.icon}`} alt={`${item.name}`} />,
              name: localStorage?.getItem('locale') === 'en' ? item.name : item.nameDe,
              link: item.url,
              external: true
            })
          }
        }
        const insert = (arr, index, ...newItems) => [
          ...arr.slice(0, index),
          ...newItems,
          ...arr.slice(index)
        ]
        const result = insert(quickLinks, 1, ...extraContent)
        setQuickLinks(result)
      } catch (err) {
        console.log(err)
        setQuickLinks(quickLinks)
      }
      setLoading(false)
    })()
  }, [])

  return (
    <div className="tenant-services-list welcome">
      {loading && <LoadingPageOverlay />}
      {hasActiveContracts ? <span /> : <HomepageOverlay />}
      {quickLinks.map((item) => {
        if (item.link === '/letters' && (
          isCondominium
            && !configStore?.lettersEnabledForCondo
            || isCondominium
            && !mixedContracts
            && !configStore?.lettersEnabledForCondo
        )) return
        if (item?.isCondominium && isCondominium && item.link !== '/letters') return;
        if (item.link === '/loyalty-program' && !menuStore.loyaltyActive) return
        if (item.link === '/heating-and-energy' && !configStore?.heatingAndEnergyEnabled) return
        if (item.link === '/receipt-inspection' && (!configStore?.ocInspectionEnabled || isCondominium)) return
        if (item.link === '/letters' && !configStore?.lettersEnabled) return
        var iconClass = "";
        if (item.link === "/heating-and-energy") iconClass = " newBadge";
        if (item.link === "/letters") iconClass = " newBadge";
        var linkClass = "";
        if (item.link === "/loyalty-program" && User.user.resident) linkClass = " disabled";
        if (localStorage.getItem("locale") === "de") iconClass += " de";

        return (
          <div key={item.link} className={"item" + linkClass}>
            <div className={"container" + iconClass}>
              {item.external ? (
                <a style={{ textDecoration: 'none' }} href={item.link} rel="nofollow noreferrer noopener" target='_blank'>
                  <div className="image">{item.image}</div>
                  <div className="name">{item.name}</div>
                </a>
              ) : (
                <div onClick={() => onClick(item.link)}>
                  <div className="image">{item.image}</div>
                  <div className="name">{item.name}</div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  )
}

TenantServicesList.propTypes = {
  menuStore: PropTypes.object.isRequired,
  configStore: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired
}

export default TenantServicesList;