import _ from 'lodash'
import { extractError } from '../service/response.service'
import { extendObservable } from 'mobx'
import { api, API_BASE } from '../service/api.service'
import { inboxCategories } from '../config'
import User from './user.store'

class AlertStore {
  constructor() {
    extendObservable(this, {
      alerts: [],
      categories: [],
      selectedCategory: 'contracts',
      schedules: [],
      loading: false
    })
  }

  getAllAlerts = () => {
    api.get('/alert')
      .then(res => {
        this.alerts = res.data

        const categories = inboxCategories

        let setActive = false
        const remappedCategories = categories.map(c => {
          c.count = 0
          c.newCount = 0
          res.data.forEach(a => {
            if (a.type === c.type && !a.seen) c.newCount++
            if (a.type === c.type) {
              c.count++
              if (!setActive) {
                c.active = true
                setActive = true
                this.selectedCategory = c.type
              }
            }
          })
          return c
        })

        this.categories = remappedCategories
      })
      .catch(err => {
        this.error = extractError(err)
        this.alerts = []
      })
  }
  markAlertRead = (id) => {
    const isNew = _.find(this.alerts, { id: id, seen: false })
    if (isNew) {
      api
        .post('/alert/mark-read', { id: id })
        .then(res => {
          this.alerts.filter(a => a.id === id)[0].seen = true
          _.find(this.categories, { type: this.selectedCategory }).newCount--
        })
        .catch((err, a) => {
          throw new Error(extractError(err))
        })
    }
  }
  markAllAlertsRead = () => {
    this.alerts.forEach((alert) => {
      const isNew = _.find(this.alerts, { id: alert.id, seen: false })
      if (isNew) {
        api
          .post('/alert/mark-read', { id: alert.id })
          .then(res => {
            this.alerts.filter(a => a.id === alert.id)[0].seen = true
            _.find(this.categories, { type: this.selectedCategory }).newCount--
          })
          .catch((err, a) => {
            throw new Error(extractError(err))
          })
      }
    })
  }
  deleteAlert = (id) => {
    api
      .delete('/alert/' + id)
      .then(res => {
        const isNew = _.find(this.alerts, { id: id, seen: false })
        const currentCategory = _.find(this.categories, { type: this.selectedCategory })
        if (isNew) currentCategory.newCount--
        currentCategory.count--
        this.alerts = this.alerts.filter(a => a.id !== id)
        this.selectedCategory = currentCategory.count ? currentCategory.type : _.find(this.categories, function (c) { return c.count > 0 }).type
      })
      .catch((err, a) => {
        throw new Error(extractError(err))
      })
  }
  handleClick = (type) => {
    this.selectedCategory = type
  }
}

export default AlertStore
