import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import t from "../service/translate.service";
import PrimaryTitle from "../components/PrimaryTitle";
import AlertCard from "../components/AlertCard";
import { extendObservable } from "mobx";
import moment from "moment";
import RepairsAndIquiriesStore from "../store/repairsAndInquiries.store";
import { PropTypes } from "prop-types";
import { getAlertInfo } from "../service/alert.service";
import find from "lodash/find";
import IconsCloseSmall from "../assets/svg/iconsCloseSmall.svg";
import withRouter from "../components/withRouter";
import TenantServicesList from "../components/TenantServicesList";
import { StackedNotifications } from "../components/StackedNotifications";

class Welcome extends Component {

  constructor(props, context) {
    super(props);
    extendObservable(this, {
      alerts: this.props.alertStore.alerts,
      schedules: this.props.alertStore.schedules,
      User: this.props.userStore
    });
    this.store = new RepairsAndIquiriesStore();
    this.store.getReports();
  }

  openReport = (id) => {
    const selectedReport = this.store.reports.filter((r) => r.id === id)[0];
    this.store.selectedReport = selectedReport;
    this.store.showResolved = selectedReport.statusId === 3;
    this.props.menuStore.setCurrentPage("/repairs-and-inquiries");
    this.props.navigate("/repairs-and-inquiries");
  };

  markAlertRead = (id) => {
    this.alerts = this.alerts.filter((a) => a.id !== id);
  };

  onClick = (link) => {
    this.props.menuStore.setCurrentPage(link);
    this.props.navigate(link);
  };
   navigateToPage(type, status){
    if(type === 'letter') this.props.navigate('/letters')
    if(type === 'oc_inspection') this.props.navigate('/receipt-inspection')
    if(status === 'emailVerification') this.props.navigate('/contracts')
  }

  render() {
    const hasPending = Boolean(
      find(this.User.contracts, (c) => !c.valid && c.active)
    );
    const { alerts, markAlertRead, markAllAlertsRead } = this.props.alertStore;

    return (
      <div>

        <PrimaryTitle title={t.WELCOME_TITLE} />

        <div className="homepage">
          <div>
            {alerts && alerts.find((alert) => alert.seen === false) && (
              <div className="clear-all-notifications-holder">
                <button
                  onClick={() => markAllAlertsRead()}
                  className="clear-all-notifications-btn"
                >
                  {t.CLEAR}
                </button>
              </div>
            )}
            <StackedNotifications
              notifications={alerts.map(alert => {
                const { sName, content } = getAlertInfo(alert);
                return {
                  ...alert,
                  content,
                  sName,
                  setDangerously: true
                }
              })}
              onClose={markAlertRead}
              onContentClick={(alert) => this.navigateToPage(alert.type, alert.status)}
            />
            {hasPending && (
              <div className="item notification-group" key="0">
                <div className="notification">
                  <div className="content">
                    <label style={{ fontFamily: "parka-bold" }}>
                      {t.PENDING_CONTRACT}
                    </label>
                    <div className="message">{t.PENDING_CONTRACT_STICKY}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {this.props.menuStore.christmasGreeting && (
            <AlertCard
              message={this.props.menuStore.christmasGreeting}
              linkUrl={this.props.menuStore.linkUrl}
              linkText={this.props.menuStore.linkText}
            />
          )}
          <div className="text-center">
            <div className="title secondary">{t.QUICK_LINKS}</div>
          </div>

          <TenantServicesList
            configStore={this.props.configStore}
            menuStore={this.props.menuStore}
            onClick={this.onClick}
            User={this.User}
          />
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  menuStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
  contractStore: PropTypes.object.isRequired,
};

export default inject("menuStore", "alertStore", 'contractStore', 'userStore', 'configStore')(withRouter(observer(Welcome)));
