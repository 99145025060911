import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import icTmpDownload from '../../assets/ic-tmp-download.png'
import icTmpPreview from '../../assets/ic-tmp-preview.png'
import signContractImg from '../../assets/signContract.png'
import pdfSvg from '../../assets/svg/pdf.svg'
import { Link } from 'react-router-dom'
import { extendObservable } from 'mobx'
import { documents } from '../../config'
import { api } from '../../service/api.service'
import t from '../../service/translate.service'
import NoContent from '../../components/NoContent'
import { ContractsImage } from '../../components/icons'
import propTypes from 'prop-types'
import DocumentStore from '../../store/documentForm.store'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { saveAs } from 'file-saver'
import historyService from '../../service/history.service'
import withRouter from '../../components/withRouter'
import { isEmpty } from 'lodash'

class Documents extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired
  }
  constructor (props) {
    super(props)

    extendObservable(this, {
      file: null,
      isFormOpen: false,
      hasActiveContracts: false
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  async getFile (filename) {
    const url = '/file/' + filename
    const res = await api
      .get(url, {
        responseType: 'blob'
      })
    return res.data
  }
  openPreviewModal = filename => {
        DocumentStore.preview = true
        this.props.navigate(`/documents/preview/${filename}`)
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })

  }
  openDocumentForm = async document => {
    DocumentStore.onOpenForm()
    this.props.navigate(`/documents/sign/${document.fileName}`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  downloadDocument = filename => {
    this.getFile(filename)
      .then(res => {
        saveAs(res, filename, {  })
      })
  }
  onClose = () => {
    DocumentStore.preview = false
  }
  render () {
    if (DocumentStore.loader) {
      return <LoadingPageOverlay />
    }

    if (isEmpty(this.props.contractStore.selectedContract)) {
      return (
        <div>
          <div className='text-center'>
            <Link to='/contracts/add-contract' className='button primary' style={{ marginBottom: '30px' }} onClick={() => historyService.push('/contracts/add-contract')}>
              {t.ADD_A_CONTRACT_BUTTON}
            </Link>
          </div>
          <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
        </div>
      )
    }
    return (
      <div>
        <div className='documents-list'>
          {documents.map(document => {
            return (
              <div key={document.fileName} className='item'>
                <div className='container'>
                  <div className='details'>
                    <div className='svg'>
                      <img src={pdfSvg} />
                    </div>
                    <div className='group'>
                      <div className='label'>{document.label}</div>
                      <div className='size'>{document.sizeInKb}K</div>
                    </div>
                  </div>
                  <div className='actions'>
                    {this.props.configStore.digitalFormsEnabled && (
                      <span className='open-document' onClick={() => this.openDocumentForm(document)}>
                        <img style={{ width: '18px', paddingTop: '1px' }} src={signContractImg} />
                      </span>
                    )}
                    <span className='open-preview' href={'/documents/' + document.fileName} onClick={e => this.openPreviewModal(document.fileName)}>
                      <img src={icTmpPreview} />
                    </span>
                    <span className='start-download' onClick={e => this.downloadDocument(document.fileName)}>
                      <img src={icTmpDownload} />
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default inject(
  'contractStore',
  'configStore'
)(withRouter(observer(Documents)))
