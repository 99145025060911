import React, { Component } from 'react'
import MobileRedirectPopUp from './MobileRedirectPopUp/MobileRedirectPopUp'
import { isSafari } from '../service/deviceDetection.service'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import find from 'lodash/find'
import logo from '../assets/gcp-logo.png'
import PropTypes from 'prop-types'
import { MenuArrowIcon } from '../components/icons'
import { menuItems } from '../config'
import Submenu from './Submenu'
import LanguagePicker from '../components/LanguagePicker'
import historyService from '../service/history.service'
import { MIN_DESKTOP_SIZE } from '../const'
import ThankYouOverlay from '../components/ThankYouOverlay'
import t from '../service/translate.service'
import withRouter from './withRouter'
import { checkIsUserCondominium } from '../service/user.service'

class SideMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isMobile: window.innerWidth < MIN_DESKTOP_SIZE,
      showAreYouSure: false,
      clickedToCloseMobilePopup: false
    }

    if (!this.state.clickedToCloseMobilePopup) {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    const isMobile = window.innerWidth < MIN_DESKTOP_SIZE
    if (isMobile !== this.state.isMobile) {
      this.setState({ isMobile })
    }
  }

  toggleExpand = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      visible: !this.state.visible
    })
  }

  setIsOpen = () => {
    this.setState({
      clickedToCloseMobilePopup: true
    })
  }

  linkClick = (path) => {
    if (path === '#') {
      this.setState({
        showAreYouSure: true
      })
    } else {
      this.setState({
        showAreYouSure: false
      })
      this.props.setCurrentPage(path)
      this.props.navigate(path)
    }
  }

  render() {
    const { currentPage, loyaltyActive, newCount, configStore, user } = this.props
    const { isMobile, visible, showAreYouSure, clickedToCloseMobilePopup } = this.state

    let isCondominium = false
    if (user.contracts) {
      isCondominium = checkIsUserCondominium(user.contracts)
    }

    const items = menuItems.map(item => {
      const subItemActive = !!find(item.submenu, { path: currentPage })
      item.active = currentPage === item.path || subItemActive

      const arrowClass = visible ? 'menu-arrow up-arrow' : 'menu-arrow'
      item.arrow = !item.submenu.length ? '' : <div className={arrowClass} onClick={this.toggleExpand}>{MenuArrowIcon}</div>
      item.newAlertsIndicator = item.path === '/inbox' && newCount ? <div className='inbox-new'>{newCount}</div> : ''
      item.subMenuMarkup = !item.submenu.length ? '' : <Submenu isCondominium={isCondominium} visible={visible} item={item} currentPage={currentPage} linkClick={this.linkClick} configStore={configStore} loyaltyActive={loyaltyActive} />
      return item
    })

    return (
      <div>
        {showAreYouSure && <ThankYouOverlay
          title={t.LOGOUT}
          description={t.LOGOUT_TEXT}
          buttonText={t.LOGOUT_YES}
          buttonLink={'/login'}
          handleClick={this.props.logoutUser}
          cancelButtonText={t.LOGOUT_NO}
          handleCancel={() => this.linkClick(currentPage)}
        />}
        <div className='navbar-wrapper'>
          <nav className='navbar navbar-expand-lg navbar-light item'>
            {!clickedToCloseMobilePopup && !isSafari && isMobile && <MobileRedirectPopUp isMobile={!clickedToCloseMobilePopup} setIsOpen={this.setIsOpen} />}
            <div className='header'>
              <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <div className='toggler-icon'>
                  <div />
                  <div />
                  <div />
                </div>
              </button>

              <Link to={'/'} className='navbar-brand' onClick={() => historyService.push('/')}>
                <img src={logo} alt="Logo" />
              </Link>

              <LanguagePicker />
            </div>

            <div className='collapse navbar-collapse' id={isMobile ? 'navbarSupportedContent' : 'menu'}>
              <ul className='navbar-nav mr-auto'>
                {items.map(item => {
                  if (item?.isCondominium && isCondominium) return null
                  return (
                    <li
                      key={item.path}
                      className={`nav-item ${item.active && 'active'}`}
                    >
                      <div className='nav-link-wrapper'>
                        <div>
                          <div className='menu-row'>
                            <Link to={item.path}
                              className='nav-link'
                              data-bs-toggle='collapse'
                              data-bs-target='#navbarSupportedContent'
                              aria-controls='navbarSupportedContent'
                              onClick={() => this.linkClick(item.path)}>
                              <span>
                                {item.svg}
                                {item.name}
                                {item.newAlertsIndicator}
                              </span>
                            </Link>
                            {item.arrow}
                          </div>
                          {item.subMenuMarkup}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

SideMenu.propTypes = {
  currentPage: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  loyaltyActive: PropTypes.bool.isRequired,
  configStore: PropTypes.object.isRequired,
  newCount: PropTypes.number,
  user: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
}
export default withRouter(observer(SideMenu))
