import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import t from "./service/translate.service";
import User from "./store/user.store";
import { Outlet, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import LoadingPageOverlay from "./components/LoadingPageOverlay";

// UserApp
import SideMenu from "./components/SideMenu";
import Footer from "./components/Footer";
import Freshchat from "./components/Freshchat";
import history from "./service/history.service";
import { menuItems } from "./config";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BackIcon } from "./components/icons";
import "./styles/app.css";
import "./styles/smooch.css";
import { ConfigProvider } from "antd";
import enUS from "antd/es/calendar/locale/en_US";
import withRouter from "./components/withRouter";
import {
  checkIfUserHasMixedContracts,
  checkIsUserCondominium,
  postRedirectHandler,
  welcomeWizardRedirect
} from "./service/user.service";
import contractService from "./service/contract.service";
import { useMsal } from "@azure/msal-react";
import { publicRoutes } from "./const/publicRoutes";
import RegistrationThankYou from "./components/RegistrationThankYou";
import { InteractionStatus } from "@azure/msal-browser";
import ThankYouOverlay from "./components/ThankYouOverlay";
import { b2cPolicies } from "./azureB2C/authConfig";
import { configRefreshRoutes } from "./const/configRefreshRoutes";

const App = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const [userLoaded, setUserLoaded] = useState(false)
  const [configLoaded, setConfigLoaded] = useState(false)
  const [emailChanged, setEmailChanged] = useState(false)
  const [passwordReseted, setPasswordReseted] = useState(false)
  const pathname = window.location.pathname
  const search = window.location.search
  const language = localStorage.getItem("locale") || "de";
  const navigate = useNavigate()
  const publicRoute = publicRoutes.find((route) => {
    if (pathname.includes(route)) {
      return route
    }
  })

  const account = accounts[0];
  useEffect(() => {
    if (!account && inProgress === InteractionStatus.None) {
      if (!publicRoute) {
        localStorage.setItem('path', pathname + search)
        window.location.replace('/login')
      }
    }
  }, [instance, accounts[0], inProgress]);

  useEffect(() => {
    (async () => {
      await instance.initialize()
      await instance
        .handleRedirectPromise()
        .then(async (res) => {
          if (res?.idTokenClaims) {
            await postRedirectHandler(res?.idTokenClaims, instance)
          }
        })
        .catch(async (err) => {
          if (err?.errorMessage?.includes("AADB2C90118")) {
            await instance.acquireTokenRedirect({
              ...b2cPolicies.authorities.emailForgotPassword,
              extraQueryParameters: { ui_locales: `${language}` }
            });
          }
          if (err?.errorMessage?.includes("AADB2C90091")) {
            console.log(888)
          } else {
            throw err;
          }
        });
    })();
  }, []);

  useEffect(() => {
    if (pathname === '/login' && localStorage.getItem('emailChanged')) {
      localStorage.removeItem('emailChanged')
      setEmailChanged(true)
    }
    if (pathname === '/login' && localStorage.getItem('passwordReseted')) {
      localStorage.removeItem('passwordReseted')
      setPasswordReseted(true)
    }
    if (accounts[0] && (pathname !== '/login' && pathname !== '/register') && !userLoaded) {
      (async () => {
        try {
          await User.loadUser()
          await props.contractStore.initContracts()
          await props.alertStore.getAllAlerts()
          setUserLoaded(true)
        } catch (err) {
          setUserLoaded(true)
        }
      })()
    }
    if (accounts[0] && (pathname === '/login' || pathname === '/register')) {
      navigate('/')
    }
  }, [accounts[0], pathname])

  useEffect(() => {
    if (!configLoaded || configRefreshRoutes.includes(pathname)) {
      props?.configStore?.getConfig()
      setConfigLoaded(true)
    }
  }, [pathname])

  const inProgressCondition = inProgress !== 'none'
  const userCondition = !User.user && !publicRoute
  const pathCondition = localStorage.getItem('path') && (pathname !== '/login' && pathname !== '/register') && !publicRoute
  if (emailChanged && pathname === '/login') {
    return (
      <ThankYouOverlay
        style={{ left: 0 }}
        title={t.THANK_YOU}
        description={t.EMAIL_CHANGED}
        buttonText={t.FORM_OK}
        buttonLink=''
        handleClick={() => {
          window.location.replace('/login')
        }}
      />
    )
  }

  if (passwordReseted && pathname === '/login') {
    return (
      <ThankYouOverlay
        style={{ left: 0 }}
        title={t.THANK_YOU}
        description={t.SAVE_PASSWORD}
        buttonText={t.FORM_OK}
        buttonLink=''
        handleClick={() => {
          window.location.replace('/login')
        }}
      />
    )
  }

  if (User.user && !inProgressCondition && !publicRoute) {
    if (!User.user.emailConfirmed) {      
      return <RegistrationThankYou />
    }
  }

  if (User?.user && User?.contracts?.length === 0 && pathname !== '/onboarding' && !localStorage.getItem('passwordReseted')) {
    welcomeWizardRedirect(User?.user)
  }

  if (inProgressCondition || userCondition || pathCondition) {
    return <LoadingPageOverlay />;
  }

  let isCondominium = false;
  let mixedContracts = false;

  if (User?.contracts) {
    isCondominium = checkIsUserCondominium(User.contracts);
    mixedContracts = checkIfUserHasMixedContracts(User.contracts);
  }

  let lettersActive = !(
    (isCondominium && !props?.configStore?.lettersEnabledForCondo) ||
    (isCondominium &&
      !mixedContracts &&
      !props?.configStore?.lettersEnabledForCondo)
  );

  lettersActive = lettersActive ? props?.configStore?.lettersEnabled : false;

  const appClass =
    window.location.hostname === "mobile-tenant-uat.grandcityproperty.de"
      ? "App uat"
      : "App";
  return (
    <ConfigProvider locale={enUS}>
      <div>
        <div className={appClass}>
          <UserAppWithRouter
            currentPage={props.menuStore.currentPage}
            setCurrentPage={props.menuStore.setCurrentPage}
            loyaltyActive={props.menuStore.loyaltyActive}
            configStore={props?.configStore}
            newCount={props.alertStore.alerts.filter((a) => !a.seen).length}
            mobileRedirectPopUpStore={props.mobileRedirectPopUpStore.isOpen}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

const UserApp = (props) => {
  const { accounts, instance } = useMsal();
  const logoutUser = () => {
    instance.logout()
  };

  const renderSubpageHeader = (pathname, menuItems, goBack, navigate) => {
    let shouldRender = ![
      "/",
      "/benefits",
      "/tenant-services",
      "/inbox",
      "/profile",
      "/login",
      "/register",
      "/loyalty-program/tmp-email-sent",
      "/loyalty-program/tmp-logged-in",
      "/onboarding",
      "/onboarding/add-contract",
      "/imprint",
      "/faq",
      "/confirm-registration"
    ].includes(pathname);

    if (
      !shouldRender ||
      pathname.includes("/reset-password") ||
      pathname.includes("/forgot-password") ||
      pathname.includes("/confirm-newsletter-subscription") ||
      pathname.includes("/confirm-registration")
    ) {
      return;
    }
    const [findMenuItem] = menuItems.filter(
      (item) => item.path === "/tenant-services"
    );
    const [findSubmenu] = findMenuItem.submenu.filter(
      (item) => item.path === pathname
    );
    let title = findSubmenu ? findSubmenu.name : t.NOT_FOUND;

    if (pathname === "/contracts") {
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname === "/contracts/add-contract") {
      title = t.ADD_A_CONTRACT_BUTTON;
      goBack = function () {
        navigate("/contracts");
      };
    } else if (pathname.includes("/contracts/prepayments")) {
      title = t.PREPAYMENT_TITLE;
      goBack = function () {
        navigate(-2);
      };
    } else if (pathname.includes("/contract-overview")) {
      title = t.CONTRACT_OVERVIEW_TITLE;
      goBack = function () {
        navigate("/contracts");
      };
    } else if (pathname.includes("/contract-overview-condo")) {
      title = t.CONTRACT_OVERVIEW_TITLE;
      goBack = function () {
        navigate("/contracts");
      };
    } else if (pathname === "/forgot-password") {
      title = t.PASSWORD_RESET_TITLE;
    } else if (pathname.includes("/rent-account/")) {
      title = t.RENT_ACCOUNT_BALANCE;
      goBack = function () {
        const contractNumber = pathname.split('/')[2]
        if (contractService.checkIsContractCondominium(contractNumber)) {
          navigate(`/contract-overview-condo/${contractNumber}`);
        } else {
          navigate(`/contract-overview/${contractNumber}`);
        }
      };
    } else if (pathname.includes("/contract-overview")) {
      title = t.CONTRACT_OVERVIEW;
      goBack = function () {
        navigate("/contracts");
      };
    } else if (pathname === "/repairs-and-inquiries") {
      title = t.REPAIRS_AND_INQUIRIES;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname === "/repairs-and-inquiries/new-request") {
      title = t.NEW_REPORT_TITLE;
      goBack = function () {
        navigate("/repairs-and-inquiries");
      };
    } else if (pathname.includes("/feedback")) {
      title = t.FEEDBACK_TITLE;
      goBack = function () {
        navigate(-2);
      };
    } else if (pathname.includes("/offer/")) {
      title = t.BENEFITS_OFFERS_TITLE;
      goBack = function () {
        navigate(-2);
      };
    } else if (pathname.includes("/offers")) {
      title = t.OFFERS_TITLE;
      goBack = function () {
        navigate("/benefits");
      };
    } else if(pathname.includes('email-verification')) {
      title = t.CONTRACT_VERIFICATION
      goBack = function () {
        navigate('/tenant-services')
      }
    }
     else if (pathname === "/documents") {
      title = t.DOCUMENTS;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname.includes("/documents/sign")) {
      title = t.DOCUMENTS;
      goBack = function () {
        navigate("/documents");
      };
    } else if (pathname.includes("/documents/preview")) {
      title = t.DOCUMENTS;
      goBack = function () {
        navigate("/documents");
      };
    } else if (pathname.includes("/loyalty-program")) {
      title = t.LOYALTY_PROGRAM;
      if (pathname === "/loyalty-program") {
        goBack = function () {
          navigate("/tenant-services");
        };
      } else if (pathname === "/loyalty-program/faq") {
        goBack = function () {
          navigate("/loyalty-program");
        };
      }
    } else if (pathname.includes("/faq")) {
      title = t.MAIN_FAQ;
      goBack = function () {
        navigate("/faq");
      };
    } else if (pathname.includes("/heating-and-energy")) {
      title = t.HEATING_AND_ENERGY_TITLE;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname.includes("/terms-and-conditions")) {
      title = t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE;
      goBack = function () {
        navigate("/loyalty-program");
      };
    } else if (pathname === "/profile/edit") {
      title = t.PROFILE_EDIT;
      goBack = function () {
        navigate("/profile");
      };
    } else if (pathname === "/profile/edit/email") {
      title = t.EMAIL_EDIT;
      goBack = function () {
        navigate("/profile");
      };
    } else if (pathname === "/profile/edit/password") {
      title = t.PASSWORD_EDIT;
      goBack = function () {
        navigate("/profile");
      };
    } else if (pathname.includes("/faq")) {
      title = t.MAIN_FAQ;
    } else if (pathname.includes("/consultations")) {
      title = t.CONSULTATIONS_TITLE;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname.includes("/receipt-inspection")) {
      title = t.RECEIPT_INSPECTION;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname.includes("/letters")) {
      title = t.LETTERS_TITLE;
      goBack = function () {
        navigate("/tenant-services");
      };
    } else if (pathname.includes("/financial-plans-and-statements")) {
        const contractNumber = pathname.split('/')[2]
        title = t.CONDO_FINANCIAL_PLANS_AND_STATEMENTS;

        if (pathname.includes('preview')) {
          title = t.OC_CALCULATION;
          goBack = function () {
            navigate(`/financial-plans-and-statements/${contractNumber}`);
          }
        } else {
          goBack = function () {
            navigate(`/contract-overview-condo/${contractNumber}`);
          }
        }
    } else if (pathname.includes("/oc-calculations")) {
      const contractNumber = pathname.split('/')[2]

      if (pathname.includes('preview')) {
        title = t.OC_CALCULATION;
        goBack = function () {
          navigate(`/oc-calculations/${contractNumber}`);
        };
      } else {
        title = t.OC_CALCULATION;
        goBack = function () {
          navigate(`/contract-overview/${contractNumber}`);
        };
      }
    }
     else if (pathname.includes("/contract-documents")) {
      title = t.CONTRACT_DOCUMENTS;
      goBack = function () {
        const contractNumber = pathname.split('/')[2]
        navigate(`/contract-overview-condo/${contractNumber}`);
      }
     } else if (pathname.includes("/rent-contract-documents")) {
      title = t.RENT_CONTRACT_DOCUMENTS;
      goBack = function () {
        const contractNumber = pathname.split('/')[2]
        navigate(`/contract-overview/${contractNumber}`);
      }
    } else if (pathname.includes('/my-payment-details')) {
      title = t.MY_PAYMENT_DETAILS
      goBack = function () {
        navigate(`/contract-overview/${pathname.split('/')[2]}`)
      }
    }

    return (
      <header>
        <div className="wrapper">
          <div className="back" onClick={() => goBack()}>
            {BackIcon}
          </div>
          <div className="title">{title}</div>
          <div />
        </div>
      </header>
    );
  };
  const pathname = window?.location?.pathname;

  let narrowClass = [
    "/contracts/add-contract",
    "/profile",
    "/profile/edit",
    "/register-success",
    "/register",
    "/login",
    "/forgot-password",
    "/loyalty-program/tmp-email-sent",
    "/onboarding",
    "/onboarding/add-contract",
  ].includes(pathname)
    ? "narrow"
    : "";

  if (pathname.includes("/reset-password") || pathname.includes("/feedback")) {
    narrowClass = "narrow";
  }

  let verticalCenterClass = [
    "/register",
    "/login",
    "/onboarding",
    "/onboarding/add-contract",
  ].includes(pathname)
    ? "vertical-top"
    : "";

  if (pathname.includes("/reset-password")) {
    verticalCenterClass = "vertical-top";
  }

  let shouldShowSideMenuAndFooter =
    User?.user &&
    !["/onboarding", "/onboarding/add-contract"].includes(pathname);

  if (
    pathname.includes("/reset-password") ||
    pathname.includes("/confirm-newsletter-subscription") ||
    pathname.includes("/confirm-registration")
  ) {
    shouldShowSideMenuAndFooter = false;
  }

  const loggedInClass = shouldShowSideMenuAndFooter ? "logged-in" : "";

  return (
    <div className={`userApp ${loggedInClass}`}>
      {shouldShowSideMenuAndFooter && (
        <div>
          <Freshchat user={User} />
          <SideMenu
            user={User}
            currentPage={props.currentPage}
            setCurrentPage={props.setCurrentPage}
            loyaltyActive={props.loyaltyActive}
            configStore={props?.configStore || {}}
            logoutUser={logoutUser}
            newCount={props.newCount}
          />
        </div>
      )}
      <main className="item">
        {renderSubpageHeader(
          pathname,
          menuItems,
          history.goBack,
          props.navigate
        )}

        <div className={`content wrapper ${narrowClass} ${verticalCenterClass}`}>
          <Outlet />
        </div>

        {shouldShowSideMenuAndFooter && <Footer />}
      </main>
    </div>
  );
};

UserApp.propTypes = {
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  loyaltyActive: PropTypes.bool.isRequired,
  configStore: PropTypes.object.isRequired,
  newCount: PropTypes.number.isRequired,
  mobileRedirectPopUpStore: PropTypes.bool,
};

const UserAppWithRouter = withRouter(UserApp);

App.propTypes = {
  children: PropTypes.element,
  menuStore: PropTypes.object.isRequired,
  contractStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
  mobileRedirectPopUpStore: PropTypes.object,
};

export default inject(
  "alertStore",
  "contractStore",
  "menuStore",
  "mobileRedirectPopUpStore",
  'configStore',
  'userStore'
)(observer(App));
