import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from '../../service/translate.service'
import { Input } from '../../components/formComponents'
import { extendObservable } from 'mobx'
import AddressSelect from '../../components/AddressSelect'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Alert } from 'antd'
import { Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import User from '../../store/user.store'
import YouTube from 'react-youtube'
import withRouter from '../../components/withRouter'
import ContractVerificationPage from './ContractVerificationPage'
import { isEmpty } from 'lodash'
import contractService from '../../service/contract.service'
class AddContract extends Component {
  static propTypes = {
    contractStore: PropTypes.object.isRequired,
    onboarding: PropTypes.bool,
    verificationError: PropTypes.string,
    contractNumber: PropTypes.string,
    address: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.props.contractStore.newContract()
    extendObservable(this, {
      submitSuccess: false,
      err: null,
      thankYouText: t.ADD_CONTRACT_THANK_YOU_TEXT,
      verificationEnabled: false,
      verificationStarted: false,
      isManual: false,
    })
  }

  componentDidMount = () => {
    (async() => {
      const verificationEnabled = this?.props?.configStore?.verificationEnabled
      if(verificationEnabled) this.verificationEnabled = true
    })()
  }

  onSubmit = e => {
    if(e) e.preventDefault()
    this.props.contractStore
      .submitContract()
      .then((res) => {
        if (res !== 'err') {
          this.thankYouText = t.ADD_CONTRACT_THANK_YOU_TEXT
          this.submitSuccess = true
        }
      })
      .catch(err => {
        this.err = err
      })
  }

  onManualSubmit = e => {
    e.preventDefault()
    this.props.contractStore
      .submitContract(true)
      .then((res) => {
        if (res !== 'err') {
          this.thankYouText = t.ADD_CONTRACT_MANUAL_THANK_YOU_TEXT
          this.submitSuccess = true
          this.props.contractStore.newContract()
          this.err = null
          this.addressSelectRef = null
        }
      })
      .catch(err => {
        console.log(err)
        this.err = err
      })
  }

  handleBack = (e) => {
    this.props.menuStore.setCurrentPage('/')
    this.props.navigate('/')
  }
  handleCancel = () => {
    this.props.navigate('/')
  }

  isTenant = () => {
    return User?.user?.tenant
  }

   handleOk = () => {
    this.props.contractStore.verificationModalOpen = false
  };

  startVerification = async () => {
    const { contractStore } = this.props;
    const { contractNumber, address } = contractStore.contract;
  
    if(isEmpty(contractNumber)) return  contractStore.error = t['VALIDATE_REQUIRED'](t['USER_TENANT_NUMBER'])
    if(isEmpty(address)) return contractStore.error = t['VALIDATE_ADDRESS'](address)
    if(!this.testContractFormat(contractNumber)) return contractStore.error = t['VALIDATE_CONTRACT_NUMBER']
    
    const contractVerified = await contractStore.getContractVerificationInfo(contractNumber)
    const contractExists = await contractStore.getContractDetails(contractNumber).catch((err) => {
      this.isManual = true
    })
    if(contractVerified) return this.onSubmit()
    if(contractExists) this.verificationStarted = true
    else  {
      contractStore.error = t['INVALID_CONTRACT']
     this.isManual = true
     return
    } 
  }


   testContractFormat = (value) => {
    const regex = /^(\d+)\/MV\w+$|^W\w+\/W\w+-\w+-\w{2}$/;
    return regex.test(value);
  }

  render() {
    const { contractStore, onboarding, verificationError, contractNumber, address } = this.props
    if(address) contractStore.contract.address = address
    const loading = contractStore.creatingContract
    if(this.err && this.err.message !== 'CONTRACT_EXISTS') {
      this.isManual = true
    }

    if(contractNumber) {
      contractStore.contract.contractNumber = contractNumber
      this.isManual = true
    } 
    const istenant = this.isTenant()
    const youtubeOptions = {
      height: '250',
      width: '400'
    }
    return (
      <div className='subpageContent'>
        {this.submitSuccess ? (
          <ThankYouOverlay
            title={t.THANK_YOU}
            description={this.thankYouText}
            buttonText={t.BACK_HOME}
            buttonLink='/'
            handleClick={this.handleOk}
          />
        ) : (
          <div>
            {verificationError && verificationError.length && 
            <Alert message={verificationError} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
            }
            <div className='title'>{t.ADD_A_CONTRACT_BUTTON}</div>
            <div className='subtitle'>
              {t.ADD_CONTRACT_SUBTITLE}
            </div>
            {contractStore?.error && (
              <Alert message={this?.props?.contractStore?.error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
            )}
            {this.verificationStarted && (
              <ContractVerificationPage configStore={this.props.configStore} isAddingContract={true}/>
            )
            }
            <Form>
                <Input
                  name='contractNumber'
                  label={t['USER_TENANT_NUMBER']}
                  type='text'
                  value={contractStore.contract.contractNumber || contractNumber}
                  onChange={e =>
                    contractStore.setVal('contractNumber', e.target.value)
                  }
                  helper={<span>{t.CONTRACT_INFO}</span>}
                  validate={contractStore.validators.contractNumber}
                />
                <AddressSelect
                  label={t.USER_ADDRESS}
                  onChange={address => contractStore.setVal('address', address)}
                  ref={ref => { this.addressSelectRef = ref }}
                  value={contractStore.contract.address}
                  validate={contractStore.validators.address(
                    contractStore.contract.address,
                    t['USER_ADDRESS']
                  )}
                />
              <Form.Group className='actions text-center'>
                {
                <Button onClick={this.verificationEnabled ? this.startVerification : this.onSubmit} className='button primary'> {this.isManual ? t.ADD_CONTRACT_AGAIN : t.ADD_CONTRACT_BUTTON}</Button>}
                {this.isManual && (
                  <Button
                    type='submit'
                    className='button primary'
                    onClick={this.onManualSubmit}
                  >
                    {t.MANUAL_APPROVAL_SUBMIT}
                  </Button>)}
                {onboarding && (<Button
                  className='button secondary'
                  onClick={this.handleCancel}
                >
                  {t.DO_THIS_LATER_BUTTON}
                </Button>)}
              </Form.Group>
            </Form>
            {onboarding && istenant && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <YouTube videoId='oHoag5w9Awc' opts={youtubeOptions} />
              </div>
            )}
            {loading && <LoadingPageOverlay />}
          </div>
        )}
      </div>
    )
  }
}

AddContract.propTypes = {
  menuStore: PropTypes.object
}
export default inject('contractStore', 'menuStore', 'configStore')(withRouter(observer(AddContract)))
